import jwtDecode from "jwt-decode";
import API from "./axioshelper";

class AuthService {
  constructor() {
    this.permissions = null;
  }

  setAxiosInterceptors = ({ onLogout }) => {
    API.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          if (onLogout) {
            onLogout();
          }
        }

        if (
          error.response &&
          (error.response.status === 500 ||
            error.response.status === 501 ||
            error.response.status === 502 ||
            error.response.status === 503 ||
            error.response.status === 504 ||
            error.response.status === 505 ||
            error.response.status === 506 ||
            error.response.status === 507 ||
            error.response.status === 508 ||
            error.response.status === 510 ||
            error.response.status === 511 ||
            error.response.status === 599)
        ) {
          error.response.data.message = "Something went wrong";
        }
        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    this.setSession(accessToken);
  }

  loginWithEmailAndPassword = (username, password) =>
    new Promise((resolve, reject) => {
      API.post("/login", {
        username,
        password,
        productValue: process.env.REACT_APP_PRODUCT_VALUE
      })
        .then(response => {
          if (response.data.username) {
            this.setSession(response.data.token);
            resolve(response.data.username);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  loginWithMobileOTP = (mobile, code) =>
    new Promise((resolve, reject) => {
      API.post("/login", {
        mobile,
        code,
        loginType: "MOBILE_OTP",
        productValue: process.env.REACT_APP_PRODUCT_VALUE
      })
        .then(response => {
          if (response.data.username) {
            this.setSession(response.data.token);
            resolve(response.data.username);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      API.get("api/user/token")
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = accessToken => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      API.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      API.defaults.headers.common.schema = true;
    } else {
      localStorage.removeItem("accessToken");
      delete API.defaults.headers.common.Authorization;
      delete API.defaults.headers.common.schema;
    }
  };

  getAccessToken = () => localStorage.getItem("accessToken");

  hasPermission(permission) {
    return this.permissions.includes(permission);
  }
  setPermissions(permissions) {
    this.permissions = permissions;
  }

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
